<template>
  <header>
    <h1>
      <img src="../assets/logo.png" alt="Logo da página" />
    </h1>

    <!-- Botão para alterar o tema -->
    <button class="button" @click="alterarTema">{{ textoBotao }}</button>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BarraLateral",
  emits: ["aoTemaAlterado"],
  data() {
    return {
      modoEscuroAtivo: false, // Indica se o modo escuro está ativo
    };
  },
  computed: {
    // Define o texto do botão com base no estado do modo escuro
    textoBotao() {
      if (this.modoEscuroAtivo) {
        return "Desativar modo escuro";
      }
      return "Ativar modo escuro";
    },
  },
  methods: {
    // Altera o tema entre claro e escuro
    alterarTema() {
      this.modoEscuroAtivo = !this.modoEscuroAtivo; // Altera o estado do modo escuro

      // Emite o evento aoTemaAlterado com o estado atual do modo escuro
      this.$emit("aoTemaAlterado", this.modoEscuroAtivo);
    },
  },
});
</script>

<style scoped>
header {
  padding: 1rem;
  background: #0d3b66;
  width: 100%;
  height: 100vh;
  text-align: center;
}
button {
  margin: 14px;
}

@media only screen and (max-width: 768px) {
  /* Estilos específicos para telas menores */
  header {
    padding: 2.5rem;
    height: auto;
  }
}
</style>
