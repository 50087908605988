<template>
  <BoxComp>
    <div class="columns">
      <div class="column is-7">
        <!-- Exibe a descrição da tarefa, ou uma mensagem padrão se não houver descrição -->
        {{ tarefa.descricao || "Tarefa sem descrição" }}
      </div>
      <div class="column">
        <!-- Passa o tempo em segundos como prop para o componente CronometroComp -->
        <CronometroComp :tempoEmSegundos="tarefa.duracaoEmSegundos" />
      </div>
    </div>
  </BoxComp>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CronometroComp from "./CronometroComp.vue";
import ITarefa from "../interfaces/ITarefa";
import BoxComp from "./BoxComp.vue";

export default defineComponent({
  name: "TarefaComp",
  components: {
    CronometroComp,
    BoxComp,
  },
  props: {
    // Propriedade para receber a tarefa como objeto
    tarefa: { type: Object as PropType<ITarefa>, required: true },
  },
});
</script>
