<template>
  <!-- Adiciona classe 'modo-escuro' se modoEscuroAtivo for verdadeiro -->
  <main
    class="columns is-gapless is-multiline"
    :class="{ 'modo-escuro': modoEscuroAtivo }"
  >
    <!-- Barra lateral para alteração de tema -->
    <div class="column is-one-quarter">
      <BarraLateral @ao-tema-alterado="trocarTema" />
      <!-- Recebe o evento ao-tema-alterado quando o tema é alterado -->
    </div>
    <div class="column is-three-quarter conteudo">
      <!-- Componente para adicionar novas tarefas -->
      <FormularioComp @ao-salvar-tarefa="salvarTarefa" />
      <!-- Recebe o evento ao-salvar-tarefa quando uma tarefa é salva -->

      <!-- Lista de tarefas -->
      <div class="lista">
        <!-- Renderiza cada tarefa -->
        <TarefaComp
          v-for="(tarefa, index) in tarefas"
          :key="index"
          :tarefa="tarefa"
        />

        <!-- Exibe mensagem se não houver tarefas -->
        <BoxComp v-if="!tarefas.length">
          Você não está muito produtivo hoje :(
        </BoxComp>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BarraLateral from "./components/BarraLateral.vue";
import FormularioComp from "./components/FormularioComp.vue";
import TarefaComp from "./components/TarefaComp.vue";
import ITarefa from "./interfaces/ITarefa";
import BoxComp from "./components/BoxComp.vue";

export default defineComponent({
  name: "App",
  components: {
    BarraLateral,
    FormularioComp,
    TarefaComp,
    BoxComp,
  },
  data() {
    return {
      // Lista de tarefas
      tarefas: [] as ITarefa[],
      // Indica se o modo escuro está ativo
      modoEscuroAtivo: false,
    };
  },
  methods: {
    // Adiciona uma nova tarefa à lista
    salvarTarefa(tarefa: ITarefa) {
      this.tarefas.push(tarefa);
    },
    // Alterna entre o modo claro e escuro
    trocarTema(modoEscuroAtivo: boolean) {
      this.modoEscuroAtivo = !this.modoEscuroAtivo;
    },
  },
});
</script>

<style>
.lista {
  padding: 1.25rem;
}

main {
  --bg-primario: #8291ae;
  --texto-primario: #14161a;
}
main.modo-escuro {
  --bg-primario: #14161a;
  --texto-primario: #fff;
}
.conteudo {
  background-color: var(--bg-primario);
}
</style>
