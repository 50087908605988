import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "column is-one-quarter" }
const _hoisted_2 = { class: "column is-three-quarter conteudo" }
const _hoisted_3 = { class: "lista" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarraLateral = _resolveComponent("BarraLateral")!
  const _component_FormularioComp = _resolveComponent("FormularioComp")!
  const _component_TarefaComp = _resolveComponent("TarefaComp")!
  const _component_BoxComp = _resolveComponent("BoxComp")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["columns is-gapless is-multiline", { 'modo-escuro': _ctx.modoEscuroAtivo }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BarraLateral, { onAoTemaAlterado: _ctx.trocarTema }, null, 8, ["onAoTemaAlterado"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormularioComp, { onAoSalvarTarefa: _ctx.salvarTarefa }, null, 8, ["onAoSalvarTarefa"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tarefas, (tarefa, index) => {
          return (_openBlock(), _createBlock(_component_TarefaComp, {
            key: index,
            tarefa: tarefa
          }, null, 8, ["tarefa"]))
        }), 128)),
        (!_ctx.tarefas.length)
          ? (_openBlock(), _createBlock(_component_BoxComp, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(" Você não está muito produtivo hoje :( ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}