import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box formulario" }
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = {
  class: "column is-8",
  role: "form",
  "aria-label": "Formulário para criação de uma nova tarefa"
}
const _hoisted_4 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemporizadorComp = _resolveComponent("TemporizadorComp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "input",
          placeholder: "Qual tarefa você deseja iniciar? ",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.descricao) = $event))
        }, null, 512), [
          [_vModelText, _ctx.descricao]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TemporizadorComp, { onAoTemporizadorFinalizar: _ctx.finalizarTarefa }, null, 8, ["onAoTemporizadorFinalizar"])
      ])
    ])
  ]))
}