import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "is-flex is-align-items-center is-justify-content-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CronometroComp = _resolveComponent("CronometroComp")!
  const _component_BotaoComp = _resolveComponent("BotaoComp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CronometroComp, { tempoEmSegundos: _ctx.tempoEmSegundos }, null, 8, ["tempoEmSegundos"]),
    _createVNode(_component_BotaoComp, {
      onClick: _ctx.dispararCronometro,
      cronometroRodando: _ctx.cronometroRodando,
      textoBotao: "play",
      classeIcone: "fa-play"
    }, null, 8, ["onClick", "cronometroRodando"]),
    _createVNode(_component_BotaoComp, {
      onClick: _ctx.finalizarCronometro,
      cronometroRodando: !_ctx.cronometroRodando,
      textoBotao: "stop",
      classeIcone: "fa-stop"
    }, null, 8, ["onClick", "cronometroRodando"])
  ]))
}