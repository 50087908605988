import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "button",
    disabled: _ctx.cronometroRodando
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass(['fas', _ctx.classeIcone])
      }, null, 2)
    ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.textoBotao), 1)
  ], 8, _hoisted_1))
}