<template>
  <div class="box formulario">
    <div class="columns">
      <div
        class="column is-8"
        role="form"
        aria-label="Formulário para criação de uma nova tarefa"
      >
        <!-- Campo de entrada de texto para a descrição da tarefa -->
        <input type="text" class="input" placeholder="Qual tarefa você deseja iniciar? " v-model="descricao"
        /> <!-- Vincula o valor do campo de entrada (descrição) ao modelo de dados 'descricao' -->
      </div>

      <div class="column">
        <TemporizadorComp @ao-temporizador-finalizar="finalizarTarefa" />
        <!-- Captura o evento ao-temporizador-finalizar para finalizar a tarefa -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TemporizadorComp from "./TemporizadorComp.vue";

export default defineComponent({
  name: "FormularioComp",
  components: {
    TemporizadorComp,
  },
  emits: ["aoSalvarTarefa"], // Define eventos emitidos pelo componente
  data() {
    return {
      descricao: "", 
    };
  },
  methods: {
    // Método para finalizar a tarefa
    finalizarTarefa(tempoDecorrido: number): void {
    
      // Emite o evento aoSalvarTarefa com a descrição e a duração da tarefa
      this.$emit("aoSalvarTarefa", {
        descricao: this.descricao,
        duracaoEmSegundos: tempoDecorrido,
      });

      // Limpa o campo de descrição da tarefa após salvar
      this.descricao = "";
    },
  },
});
</script>

<style>
.formulario {
  color: var(--texto-primario); 
  background: var(--bg-primario);
}
</style>
