import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-7" }
const _hoisted_3 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CronometroComp = _resolveComponent("CronometroComp")!
  const _component_BoxComp = _resolveComponent("BoxComp")!

  return (_openBlock(), _createBlock(_component_BoxComp, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.tarefa.descricao || "Tarefa sem descrição"), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CronometroComp, {
            tempoEmSegundos: _ctx.tarefa.duracaoEmSegundos
          }, null, 8, ["tempoEmSegundos"])
        ])
      ])
    ]),
    _: 1
  }))
}